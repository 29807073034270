import { DSL } from '@/store/type-map';
import { HELMMGMT } from '@/config/types';

export const NAME = 'helmManager';

export function init(store) {
  const {
    product,
    basicType,
    virtualType,
  } = DSL(store, NAME);

  product({
    weight:              2,
    inStore:             'management',
    icon:                'apps',
    removable:           false,
    showClusterSwitcher: false,
    category:            'business',
  });

  basicType([
    HELMMGMT.LIST,
    HELMMGMT.INSTALL,
    HELMMGMT.UNINSTALL,
    HELMMGMT.HISTORY,
    HELMMGMT.UPDATE,
    HELMMGMT.ROLLBACK,
    HELMMGMT.REPO_UPDATE,
    HELMMGMT.HELM_CONFIG,
  ]);

  virtualType({
    labelKey:       'kunYao.helm.list.title',
    name:           HELMMGMT.LIST,
    weight:         7,
    icon:           'globe',
    route:          {
      name:   'c-cluster-helmManager-list',
      params: {
        product:  NAME,
        resource: HELMMGMT.LIST
      }
    }
  });

  virtualType({
    labelKey:       'kunYao.helm.install.title',
    name:           HELMMGMT.INSTALL,
    weight:         6,
    icon:           'globe',
    route:          {
      name:   'c-cluster-helmManager-install',
      params: {
        product:  NAME,
        resource: HELMMGMT.INSTALL
      }
    }
  });

  virtualType({
    labelKey:       'kunYao.helm.uninstall.title',
    name:           HELMMGMT.UNINSTALL,
    weight:         5,
    icon:           'globe',
    route:          {
      name:   'c-cluster-helmManager-uninstall',
      params: {
        product:  NAME,
        resource: HELMMGMT.UNINSTALL
      }
    }
  });

  virtualType({
    labelKey:       'kunYao.helm.history.title',
    name:           HELMMGMT.HISTORY,
    weight:         4,
    icon:           'globe',
    route:          {
      name:   'c-cluster-helmManager-history',
      params: {
        product:  NAME,
        resource: HELMMGMT.HISTORY
      }
    }
  });
  virtualType({
    labelKey:       'kunYao.helm.update.title',
    name:           HELMMGMT.UPDATE,
    weight:         3,
    icon:           'globe',
    route:          {
      name:   'c-cluster-helmManager-update',
      params: {
        product:  NAME,
        resource: HELMMGMT.UPDATE
      }
    }
  });
  virtualType({
    labelKey:       'kunYao.helm.rollback.title',
    name:           HELMMGMT.ROLLBACK,
    weight:        2,
    icon:           'globe',
    route:          {
      name:   'c-cluster-helmManager-rollback',
      params: {
        product:  NAME,
        resource: HELMMGMT.ROLLBACK
      }
    }
  });
  virtualType({
    labelKey:       'kunYao.helm.helm_config.title',
    name:           HELMMGMT.HELM_CONFIG,
    weight:         0,
    icon:           'globe',
    route:          {
      name:   'c-cluster-helmManager-helmConfig',
      params: {
        product:  NAME,
        resource: HELMMGMT.HELM_CONFIG
      }
    }
  });
}
