<script>
import LabeledInput from '@/components/form/LabeledInput';
import LabeledSelect from '@/components/form/LabeledSelect';
import RadioGroup from '@/components/form/RadioGroup';
import CruResource from '@/components/CruResource';
import createEditView from '@/mixins/create-edit-view';
import { CMDBMGMT } from '@/config/types';

export default {
  components: {
    CruResource,
    LabeledInput,
    LabeledSelect,
    RadioGroup
  },

  mixins: [createEditView],

  async fetch() {
    const cluster = (await this.$store.dispatch('kunYao/findAll', { type: CMDBMGMT.CLUSTERLIST })).filter(item => !item.is_deleted);
    const network = (await this.$store.dispatch('kunYao/findAll', { type: CMDBMGMT.NETWORKLIST })).filter(item => !item.is_deleted);

    this.clusterOption = cluster.map(item => ({ label: item.name, value: +item.id }));
    this.networkOption = network.map(item => ({ label: item.name, value: +item.id }));
  },

  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'create'
    },
  },

  data() {
    return {
      clusterOption:         [],
      networkOption:         [],
      labelOption:           [],
      wireguardStatusOption: ['Offline', 'Online'],
      installStatusOption:   ['Blank', 'Installing', 'Installed', 'Uninstalling', 'Uninstalled'],
      boolOption:            [{ label: 'false', value: false }, { label: 'true', value: true }]
    };
  },

  beforeMount() {
    !this.value.network && this.$set(this.value, 'network', { id: '' });
    !this.value.cluster && this.$set(this.value, 'cluster', { id: '' });
  },

  methods: {
    onSave(btnCb) {
      if (this.isCreate) {
        this.value.wireguard_status = 0;
        this.value.install_status = 0;
        this.value.ssh_port = 62534;
        this.value.is_deleted = false;
        this.value.is_labels_enabled = true;
        this.value.is_k3s_master = false;
        this.value.network = this.value.network.id ?? this.value.network;
        this.value.cluster = this.value.cluster.id ?? this.value.cluster;
      }

      this.save(btnCb);
    },
    error() {
      this.errors = [];
    }
  }
};

</script>

<template>
  <div class="filled-height">
    <CruResource
      :resource="{}"
      :can-yaml="false"
      :mode="mode"
      :errors="errors"
      :done-route="doneRoute"
      @error="error"
      @finish="onSave"
    >
      <div v-if="isCreate">
        <div class="row mb-20">
          <div class="col span-6">
            <LabeledSelect
              v-model="value.network.id"
              :mode="mode"
              :label="t('tableHeaders.network')"
              :options="networkOption"
              required
            />
          </div>
          <div class="col span-6">
            <LabeledSelect
              v-model="value.cluster.id"
              :mode="mode"
              :label="t('tableHeaders.cluster')"
              :options="clusterOption"
              required
            />
          </div>
        </div>
        <div class="row mb-20">
          <div class="col span-6">
            <LabeledInput
              v-model="value.private_ip"
              :mode="mode"
              :label="t('tableHeaders.privateIp')"
            />
          </div>
          <div class="col span-6">
            <LabeledInput
              v-model="value.wireguard_ip"
              :mode="mode"
              :label="t('tableHeaders.wireGuardIp')"
              required
            />
          </div>
        </div>
        <div class="row mb-20">
          <div class="col span-6">
            <LabeledInput
              v-model="value.k3s_master_ip"
              :mode="mode"
              :label="t('tableHeaders.k3sMasterIp')"
            />
          </div>
          <div class="col span-6">
            <LabeledInput
              v-model="value.comment"
              :mode="mode"
              :label="t('tableHeaders.comment')"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row mb-20">
          <div class="col span-6">
            <LabeledSelect
              v-model="value.wireguard_status"
              :mode="mode"
              :label="t('tableHeaders.wireGuardHealth')"
              :options="wireguardStatusOption"
              required
            />
          </div>
          <div class="col span-6">
            <LabeledSelect
              v-model="value.install_status"
              :mode="mode"
              :label="t('tableHeaders.installStatus')"
              :options="installStatusOption"
              required
            />
          </div>
        </div>
        <div class="row mb-20">
          <div class="col span-6">
            <LabeledSelect
              v-model="value.network.id"
              :mode="mode"
              :label="t('tableHeaders.network')"
              :options="networkOption"
              required
            />
          </div>
          <div class="col span-6">
            <LabeledSelect
              v-model="value.cluster.id"
              :mode="mode"
              :label="t('tableHeaders.cluster')"
              :options="clusterOption"
              required
            />
          </div>
        </div>
        <div class="row mb-20">
          <div class="col span-6">
            <LabeledInput
              v-model="value.private_ip"
              :mode="mode"
              :label="t('tableHeaders.privateIp')"
            />
          </div>
          <div class="col span-6">
            <LabeledInput
              v-model="value.wireguard_ip"
              :mode="mode"
              :label="t('tableHeaders.wireGuardIp')"
              required
            />
          </div>
        </div>
        <div class="row mb-20">
          <div class="col span-6">
            <LabeledInput
              v-model="value.k3s_master_ip"
              :mode="mode"
              :label="t('tableHeaders.k3sMasterIp')"
            />
          </div>
          <div class="col span-6">
            <LabeledSelect
              v-model="value.is_k3s_master"
              :mode="mode"
              :label="t('tableHeaders.isK3sMaster')"
              :options="boolOption"
            />
          </div>
        </div>
        <div class="row mb-20">
          <div class="col span-6">
            <LabeledInput
              v-model="value.ssh_port"
              :mode="mode"
              :label="t('tableHeaders.sshPort')"
            />
          </div>
          <div class="col span-6">
            <LabeledInput
              v-model="value.comment"
              :mode="mode"
              :label="t('tableHeaders.comment')"
            />
          </div>
        </div>
        <div class="row mb-20">
          <div class="col span-3">
            <RadioGroup
              v-model="value.is_labels_enabled"
              name="is_labels_enabled"
              :label="t('tableHeaders.isLabelsEnabled')"
              :labels="['false', 'true']"
              :options="[false, true]"
              :mode="mode"
            />
          </div>
          <div class="col span-3">
            <RadioGroup
              v-model="value.is_deleted"
              name="is_deleted"
              :label="t('tableHeaders.isDeleted')"
              :labels="['false', 'true']"
              :options="[false, true]"
              :mode="mode"
            />
          </div>
        </div>
      </div>
    </CruResource>
  </div>
</template>

<style scoped>

</style>>
