import { LABELMGMT, CMDBMGMT, SERVERMGMT } from '@/config/types';

export default [
  {
    id:                 LABELMGMT.SERVELABEL,
    type:              'schema',
    description:       'The TopologySelectorTerm type implements a subset of the NodeSelectorTerm.',
    pluralName:        'ution.nodeSelectorTermses',
    links:             {
      collection: '/api/v1/label_server',
      self:       '/api/v1/label_server',
      remove:     '/api/v1/label_server',
      update:     '/api/v1/label_server',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                 LABELMGMT.CONFIGTAG,
    type:              'schema',
    description:       'The TopologySelectorTerm type implements a subset of the NodeSelectorTerm.',
    pluralName:        'ution.nodeSelectorTermses',
    links:             {
      collection: '/api/v1/label?category=1',
      self:       '/api/v1/label',
      update:       '/api/v1/label',
      remove:       '/api/v1/label',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                 LABELMGMT.PROJECTLABEL,
    type:              'schema',
    description:       'The TopologySelectorTerm type implements a subset of the NodeSelectorTerm.',
    pluralName:        'ution.nodeSelectorTermses',
    links:             {
      collection: '/api/v1/label?category=2',
      self:       '/api/v1/label',
      update:       '/api/v1/label',
      remove:       '/api/v1/label',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                 LABELMGMT.RELEASEMANAGEMENT,
    type:              'schema',
    description:       'The TopologySelectorTerm type implements a subset of the NodeSelectorTerm.',
    pluralName:        'ution.nodeSelectorTermses',
    links:             {
      collection: '/api/v1/label_release',
      self:       '/api/v1/label_release',
      update:       '/api/v1/label_release',
      remove:       '/api/v1/label_release',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                CMDBMGMT.PROJECTLIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/project',
      update:     '/api/v1/project',
      remove:     '/api/v1/project',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                CMDBMGMT.KYNAMESPACELIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/namespace',
      update:     '/api/v1/namespace',
      remove:     '/api/v1/namespace',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                CMDBMGMT.NETWORKLIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/network',
      update:     '/api/v1/network',
      remove:     '/api/v1/network',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                CMDBMGMT.SERVERLIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/label_server',
      update:     '/api/v1/server',
      remove:     '/api/v1/server',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                CMDBMGMT.CLUSTERLIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/cluster',
      update:     '/api/v1/cluster',
      remove:     '/api/v1/cluster',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                CMDBMGMT.JUMPSERVERLIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/jump_server',
      update:     '/api/v1/jump_server',
      remove:     '/api/v1/jump_server',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                CMDBMGMT.LABELLIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/label',
      update:     '/api/v1/label',
      remove:     '/api/v1/label',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                CMDBMGMT.RELEASELIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/label_release',
      update:     '/api/v1/label_release',
      remove:     '/api/v1/label_release',
    },
    collectionMethods: ['POST'],
  },

  {
    id:                SERVERMGMT.SERVERLIST,
    type:              'schema',
    links:             {
      collection: '/api/v1/server',
      update:     '/api/v1/server',
      remove:     '/api/v1/server',
    },
    collectionMethods: ['POST'],
  },
];
