import NormanModel from './norman-class';
import schemas from '@/config/schemas/kunYao';
import isEmpty from 'lodash/isEmpty';
import { insertAt, isArray } from '@/utils/array';
import { LABELMGMT, CMDBMGMT } from '@/config/types';
import { clone } from '@/utils/object';

export default class CMDBModel extends NormanModel {
  get doneRoute() {
    return 'c-cluster-cmdb-resource';
  }

  get parentNameOverride() {
    const product = this.$rootGetters['productId'];

    const allTypes = this.$rootGetters['type-map/allTypes'](product);

    return this.t(allTypes[this.type].labelKey);
  }

  get listLocation() {
    return { name: this.doneRoute, params: { resource: this.type } };
  }

  get doneOverride() {
    return this.listLocation;
  }

  get parentLocationOverride() {
    return this.listLocation;
  }

  remove() {
    this.setIsDeleted(true);
  }

  async setIsDeleted(enabled) {
    try {
      const opt = {
        url:    `${ this.linkFor('remove') }/${ this.id }`,
        method: 'patch',
        data:   { is_deleted: enabled },
      };

      const res = await this.$dispatch('request', opt);

      if ( res?._status === 200) {
        const schema = schemas.find(item => item.id === this.type);

        res.id = `${ res?.id || this.id }`;
        res.type = schema.id;
        res.links = schema.links;
        await this.$dispatch('load', { data: res });
      }
    } catch (e) {
      if ( this.type && this.id && e?._status === 409) {
        // If there's a conflict, try to load the new version
        await this.$dispatch('find', {
          type: this.type,
          id:   this.id,
          opt:  { force: true }
        });
      }

      return Promise.reject(e);
    }
  }

  recovery() {
    this.setIsDeleted(false);
  }

  get _availableActions() {
    const out = super._availableActions;

    insertAt(out, 0, {
      action:   'recovery',
      label:    this.t('action.recovery'),
      bulkable: true,
      enabled:  this.is_deleted
    });

    insertAt(out, 1, { divider: true, enabled: this.is_deleted });

    return out;
  }

  get _canDelete() {
    return this.hasLink('remove') && this.$rootGetters['type-map/optionsFor'](this.type).isRemovable && !this.is_deleted;
  }

  get canCustomEdit() {
    return this.$rootGetters['type-map/hasCustomEdit'](this.type, this.id) && !this.is_deleted;
  }

  save() {
    if (this.id) {
      const opt = { url: `${ this.linkFor('update') }/${ this.id }` };

      return this._save(opt);
    } else {
      return this._save();
    }
  }

  async _save(opt = {}) {
    delete this.__rehydrate;
    delete this.__clone;
    const forNew = !this.id;

    const errors = await this.validationErrors(this, opt.ignoreFields);

    if (!isEmpty(errors)) {
      return Promise.reject(errors);
    }

    if ( !opt.url ) {
      if ( forNew ) {
        const schema = this.$getters['schemaFor'](this.type);
        const url = schema.linkFor('update');

        opt.url = url;
      } else {
        opt.url = this.linkFor('update') || this.linkFor('self');
      }
    }

    if ( !opt.method ) {
      opt.method = ( forNew ? 'post' : 'put' );
    }

    if ( !opt.headers ) {
      opt.headers = {};
    }

    if ( !opt.headers['content-type'] ) {
      opt.headers['content-type'] = 'application/json';
    }

    if ( !opt.headers['accept'] ) {
      opt.headers['accept'] = 'application/json';
    }

    // @TODO remove this once the API maps steve _type <-> k8s type in both directions
    opt.data = { ...this };

    if (opt?.data._name) {
      opt.data.name = opt.data._name;
    }

    if (opt?.data._labels) {
      opt.data.labels = opt.data._labels;
    }

    if (opt?.data._annotations) {
      opt.data.annotations = opt.data._annotations;
    }

    try {
      let res = await this.$dispatch('request', opt);
      const schema = schemas.find(item => item.id === this.type);

      if (res.code && res.msg && res.data) {
        if (isArray(res.data)) {
          res = res.data[0];
        } else {
          res = res.data;
        }
      }

      res.id = `${ res?.id || this?.id }`;
      res.type = schema.id;
      res.links = schema.links;
      // console.log('### Resource Save', this.type, this.id);

      const dictionaries = {
        [CMDBMGMT.SERVERLIST]:  LABELMGMT.SERVELABEL,
        [CMDBMGMT.LABELLIST]:   res.category === 1 ? LABELMGMT.CONFIGTAG : LABELMGMT.PROJECTLABEL,
        [CMDBMGMT.RELEASELIST]: LABELMGMT.RELEASEMANAGEMENT,
      };

      // Steve sometimes returns Table responses instead of the resource you just saved.. ignore
      if ( res && res.kind !== 'Table') {
        await this.$dispatch('load', { data: res, existing: (forNew ? this : undefined ) });
        const repeatModule = dictionaries[res.type];

        if (repeatModule) {
          const data = clone(res);

          data.type = repeatModule;
          await this.$dispatch('load', { data, existing: (forNew ? this : undefined ) });
        }
      }
    } catch (e) {
      if ( this.type && this.id && e?._status === 409) {
        // If there's a conflict, try to load the new version
        await this.$dispatch('find', {
          type: this.type,
          id:   this.id,
          opt:  { force: true }
        });
      }

      return Promise.reject(e);
    }

    return this;
  }
}
