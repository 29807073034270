<script>
import CopyToClipboardText from '@/components/CopyToClipboardText';
import { ellipsisByLength } from '@/utils/string';

export default {
  components: { CopyToClipboardText },

  props: {
    value: {
      type:    String,
      default: ''
    },
    ellipsis: {
      type:    Boolean,
      default: false
    },
  },

  methods: { ellipsisByLength }
};
</script>

<template>
  <CopyToClipboardText :text="value" :show-text="ellipsis ? ellipsisByLength(value) : ''" />
</template>
