<script>
import LabeledInput from '@/components/form/LabeledInput';
import CruResource from '@/components/CruResource';
import createEditView from '@/mixins/create-edit-view';
import RadioGroup from '@/components/form/RadioGroup';

export default {
  components: {
    CruResource,
    LabeledInput,
    RadioGroup,
  },

  mixins: [createEditView],

  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'create'
    },
  },

  methods: {
    onSave(btnCb) {
      if (this.isCreate) {
        this.value.is_deleted = false;
      }
      this.save(btnCb);
    },
    error() {
      this.errors = [];
    }
  }
};

</script>

<template>
  <div class="filled-height">
    <CruResource
      :resource="{}"
      :can-yaml="false"
      :mode="mode"
      :errors="errors"
      :done-route="doneRoute"
      @error="error"
      @finish="onSave"
    >
      <div class="row mb-20">
        <div class="col span-6">
          <LabeledInput
            v-model="value.name"
            :mode="mode"
            :label="t('tableHeaders.name')"
          />
        </div>
      </div>
      <div v-if="!isCreate">
        <div class="row mb-20">
          <div class="col span-6">
            <RadioGroup
              v-model="value.is_deleted"
              name="is_deleted"
              :label="t('tableHeaders.isDeleted')"
              :labels="['false', 'true']"
              :options="[false, true]"
              :mode="mode"
            />
          </div>
        </div>
      </div>
    </CruResource>
  </div>
</template>

<style scoped>

</style>>
