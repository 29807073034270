import NormanModel from '@/plugins/steve/norman-class';
import { CMDBMGMT, SERVERMGMT } from '@/config/types';

const resource = SERVERMGMT.SERVERLIST;

export default class Server extends NormanModel {
  constructor(data, ctx, rehydrateNamespace = null, setClone = false) {
    super(data, ctx, rehydrateNamespace = null, setClone = false);

    let selfLabels = {};

    data?.labels?.forEach((item) => {
      selfLabels = { ...selfLabels, ...item.label };
    });

    this.selfLabels = selfLabels;
  }

  noJoinMater() {
    return this.is_k3s_master === false &&
    (
      this.install_status === 'Blank' ||
      this.install_status === 'Uninstalled' ||
      this.install_status === 'Uninstalling'
    );
  }

  get _availableActions() {
    return [
      {
        action:     'onJoin',
        label:      this.t('kunYao.button.join'),
        // label:      this.$rootGetters['i18n/t']('action.activate'),
        bulkable:   true,
        bulkAction: 'joinBulk',
        enabled:    this.noJoinMater(),
        weight:     2
      },
      {
        action:     'onExit',
        label:      this.t('kunYao.button.exit'),
        bulkable:   true,
        bulkAction: 'exitBulk',
        enabled:    !this.noJoinMater(),
        weight:     1
      },
      { divider: true },
      // ...super._availableActions,
    ];
  }

  updateList() {
    this.$dispatch('kunYao/findAll', { type: resource, opt: { force: true } });
  }

  async joinMaterIp(param) {
    const masterList = [];
    const wireguards = (await this.$dispatch('kunYao/findAll', { type: CMDBMGMT.NETWORKLIST })).filter(item => !item.is_deleted);

    this.serverList.forEach((item) => { // ?
      if (item.is_k3s_master) {
        masterList.push(item.wireguard_ip || ' ');
      }
    });

    await this.$dispatch('cluster/promptModal', {
      component: 'customDialog',
      resources: {
        importVue:  ['LabeledInput', 'LabeledSelect'],
        components: [
          {
            component: 'LabeledInput',
            cProps:    {
              label:    'NodeName',
              value:    `${ this.network.name }-${ this.private_ip || '' }`
            },
            isView: true,
          },
          {
            component: 'LabeledSelect',
            cProps:    {
              label:    this.$rootGetters['i18n/t']('kunYao.servers.inputLabel.selectMasterNode'),
              options:   masterList,
              required: true
            },
            name: 'k3s_master_ip',
          },
          {
            component: 'LabeledSelect',
            cProps:    {
              label:    this.$rootGetters['i18n/t']('kunYao.servers.inputLabel.selectWireguardSubnet'),
              options:   wireguards.map((item, index) => ({ label: item.idc_name || ' ', value: item.id })),
              required: true
            },
            name: 'network',
          },
        ],
        save: async({ buttonCb, data, close }) => {
          try {
            await this.$store
              .dispatch('request', {
                url:    `/api/v1/server/${ this.id }/install`,
                method: 'POST',
                data:   {
                  jump_server:   param.jump_server,
                  k3s_master_ip: data.k3s_master_ip,
                  cluster:       this.cluster.id,
                  network:       data.network
                }
              });
            await this.updateList();
            buttonCb(true);
            close();
          } catch (e) {
            buttonCb(false);
          }
        },
      },
    });
  }

  async onJoin() {
    const jumpServers = (await this.$dispatch('request', { url: `/api/v1/jump_server?network=${ this.network.id }` })).data.filter(item => !item.is_deleted);
    const publicIps = jumpServers.map(item => ({ label: item.public_ip, value: item.id })) || [];

    await this.$dispatch('cluster/promptModal', {
      component: 'customDialog',
      resources: {
        importVue:  ['LabeledSelect'],
        components: [{
          component: 'LabeledSelect',
          cProps:    {
            label:    this.$rootGetters['i18n/t']('kunYao.servers.inputLabel.selectJumpServer'),
            options:  publicIps,
            required: true
          },
          name: 'jump_server',
        }],
        mode:       'continue',
        save:       ({ buttonCb, data }) => {
          try {
            if (data.jump_server) {
              buttonCb(true);
              this.joinMaterIp({ jump_server: data.jump_server });
            } else {
              buttonCb(false);
            }
          } catch (e) {
            buttonCb(false);
          }
        },
      },
    }, { root: true });
  }

  async onExit() {
    const info = this.$rootGetters['i18n/t']('kunYao.servers.verify');
    const jumpServers = (await this.$dispatch('request', { url: `/api/v1/jump_server?network=${ this.network.id }` })).data.filter(item => !item.is_deleted);
    const publicIps = jumpServers.map(item => ({ label: item.public_ip, value: item.id })) || [];

    await this.$dispatch('cluster/promptModal', {
      component: 'customDialog',
      resources: {
        importVue:  ['LabeledInput', 'LabeledSelect'],
        components: [
          {
            component: 'LabeledSelect',
            cProps:    {
              label:    this.$rootGetters['i18n/t']('kunYao.servers.inputLabel.selectJumpServer'),
              options:  publicIps,
              required: true
            },
            name: 'jump_server',
          },
          {
            component:      'LabeledInput',
            cProps:    {
              label:    this.$rootGetters['i18n/t']('validation.input', { key: info }),
              required: true
            },
            name: 'info'
          }
        ],
        save: async({
          buttonCb, data, addError, close
        }) => {
          if ( data.info === info) {
            try {
              await this.$dispatch('request', {
                url:    `/api/v1/server/${ this.id }/uninstall`,
                method: 'POST',
                data:   { jump_server: data.jump_server }
              });
              await this.updateList();
              buttonCb(true);
              close();
            } catch (e) {
              addError([e]);
              buttonCb(false);
            }
          } else {
            addError(['error']);
            buttonCb(false);
          }
          setTimeout(() => {
            addError([]);
          }, 5000);
        },
      }
    }, { root: true });
  }

  async joinBulk(items) {
    const jumpServers = (await this.$dispatch('request', { url: `/api/v1/jump_server?network=${ this.network.id }` })).data.filter(item => !item.is_deleted);
    const publicIps = jumpServers.map(item => ({ label: item.public_ip, value: item.id })) || [];

    await this.$dispatch('cluster/promptModal', {
      component: 'customDialog',
      resources: {
        importVue:  ['LabeledSelect'],
        components: [{
          component: 'LabeledSelect',
          cProps:    {
            label:    this.$rootGetters['i18n/t']('kunYao.servers.inputLabel.selectJumpServer'),
            options:  publicIps,
            required: true
          },
          name: 'jump_server',
        }],
        mode:       'continue',
        save:       async({ buttonCb, data }) => {
          try {
            if (data.jump_server) {
              for (const item of items) {
                await item.joinMaterIp({ jump_server: data.jump_server });
              }
            } else {
              buttonCb(false);
            }
          } catch (e) {
            buttonCb(false);
          }
        },
      },
    }, { root: true });
  }

  async exitBulk(items) {
    const info = this.$rootGetters['i18n/t']('kunYao.servers.verify');

    const jumpServers = (await this.$dispatch('request', { url: `/api/v1/jump_server?network=${ this.network.id }` })).data.filter(item => !item.is_deleted);
    const publicIps = jumpServers.map(item => ({ label: item.public_ip, value: item.id })) || [];

    await this.$dispatch('cluster/promptModal', {
      component: 'customDialog',
      resources: {
        importVue:  ['LabeledInput', 'LabeledSelect'],
        components: [
          {
            component: 'LabeledSelect',
            cProps:    {
              label:    this.$rootGetters['i18n/t']('kunYao.servers.inputLabel.selectJumpServer'),
              options:  publicIps,
              required: true
            },
            name: 'jump_server',
          },
          {
            component:      'LabeledInput',
            cProps:    {
              label:    this.$rootGetters['i18n/t']('validation.input', { key: info }),
              required: true
            },
            name: 'info'
          }
        ],
        save: async({
          buttonCb, data, addError, close
        }) => {
          if ( data.info === info) {
            try {
              for (const item of items) {
                await this.$dispatch('request', {
                  url:    `/api/v1/server/${ item.id }/uninstall`,
                  method: 'POST',
                  data:   { jump_server: data.jump_server }
                });
              }
              await this.updateList();
              buttonCb(true);
              close();
            } catch (e) {
              addError([e]);
              buttonCb(false);
            }
          } else {
            addError(['error']);
            buttonCb(false);
          }
          setTimeout(() => {
            addError([]);
          }, 5000);
        },
      }
    }, { root: true });
  }
}
