<script>
export default {
  props: {
    value: {
      type:    [String, Number],
      default: '-'
    }
  },
};
</script>

<template>
  <div>
    <template v-if="value">
      {{ value }}
    </template>
    <template v-else>
      -
    </template>
  </div>
</template>
