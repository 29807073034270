import Label from './label';
import { NORMAN, LABELMGMT } from '@/config/types';
import { _EDIT } from '@/config/query-params';
import { allHash } from '@/utils/promise';
import toPairs from 'lodash/toPairs';
export default class ServerLabel extends Label {
  constructor(data, ctx, rehydrateNamespace = null, setClone = false) {
    super(data, ctx, rehydrateNamespace = null, setClone = false);

    let selfLabels = {};

    data?.labels?.forEach((item) => {
      selfLabels = { ...selfLabels, ...item.label };
    });

    this.selfLabels = selfLabels;
  }

  canActivate(state) {
    return state !== this.is_labels_enabled;
  }

  get canCustomEdit() {
    return this.$rootGetters['type-map/hasCustomEdit'](this.type, this.id) && !this.is_labels_enabled;
  }

  get _availableActions() {
    return [
      {
        action:     'activate',
        label:      this.t('action.activate'),
        icon:       'icon icon-play',
        bulkable:   true,
        bulkAction: 'activateBulk',
        enabled:    this.canActivate(true),
        weight:     2
      },
      {
        action:     'deactivate',
        label:      this.t('action.deactivate'),
        icon:       'icon icon-pause',
        bulkable:   true,
        bulkAction: 'deactivateBulk',
        enabled:    this.canActivate(false),
        weight:     1
      },
      { divider: true },
      ...super._availableActions,
      {
        action:     'removeAll',
        label:      this.t('kunYao.button.clear'),
        bulkable:   true,
        bulkAction: 'removeAllBulk',
        enabled:    this.canActivate(true) && !!this.labels?.length,
        weight:     1
      },
      {
        action:     this.canUpdate ? 'goToEdit' : 'goToViewConfig',
        label:      this.t(this.canUpdate ? 'action.edit' : 'action.view'),
        // icon:       'icon icon-edit',
        bulkable:   true,
        bulkAction: 'addLabelBulk',
        enabled:    this.canActivate(true),
        weight:     1
      },
    ];
  }

  async addLabelBulk(items) {
    const hash = await allHash({
      configLabels:  this.$dispatch('kunYao/findAll', { type: LABELMGMT.CONFIGTAG }, { root: true }),
      projectLabels: this.$dispatch('kunYao/findAll', { type: LABELMGMT.PROJECTLABEL }, { root: true }),
    });
    const configLabelOption = hash.configLabels.filter(item => !item.is_deleted).map(item => ({ label: toPairs(item.label)[0].join('='), value: item.id }));
    const projectLabelOption = hash.projectLabels.filter(item => !item.is_deleted).map(item => ({ label: toPairs(item.label)[0].join('='), value: item.id }));

    await this.$dispatch('cluster/promptModal', {
      component: 'customDialog',
      resources: {
        importVue:  ['ArrayListSelect'],
        components: [
          {
            component: 'ArrayListSelect',
            cProps:    {
              options:            configLabelOption,
              'array-list-props': {
                mode:     _EDIT,
                addLabel: this.$rootGetters['i18n/t']('generic.add'),
                title:    this.$rootGetters['i18n/t']('tableHeaders.configLabel'),
              }
            },
            name:  'configLabel',
            value: [],
          },
          {
            component: 'ArrayListSelect',
            cProps:    {
              options:            projectLabelOption,
              'array-list-props': {
                mode:     _EDIT,
                addLabel: this.$rootGetters['i18n/t']('generic.add'),
                title:    this.$rootGetters['i18n/t']('tableHeaders.configLabel'),
              }
            },
            name:  'projectLabel',
            value: [],
          },
        ],
        save: async({ buttonCb, data, close }) => {
          try {
            for (const item of items) {
              const labeIds = item.labels.map(i => `${ i.id }`);
              const labels = data.projectLabel.concat(labeIds).concat(data.configLabel).filter(item => item);

              item.labels = Array.from(new Set(labels)).join(',');

              await item.save();
            }

            buttonCb(true);
            close();
          } catch (e) {
            buttonCb(false);
          }
        },
      },
    }, { root: true });
  }

  async removeAll() {
    this.labels = [];

    await this.save();
  }

  async removeAllBulk(items) {
    for (const item of items) {
      await item.removeAll();
    }
  }

  async getV3Node() {
    const opt = { params: { ipAddress: this.wireguard_ip }, force: true };

    return await this.$ctx.dispatch('rancher/findAll', { type: NORMAN.NODE, opt }, { root: true });
  }

  async setEnabled(enabled) {
    let rotationNum = 3;
    let outTime = 200;

    try {
      const node = (await this.getV3Node())[0];

      const deleteKey = [];

      if (enabled) {
        node.setLabels({
          ...node.labels,
          ...this.selfLabels,
        });
      } else {
        for (const key in this.selfLabels) {
          if (Object.hasOwnProperty.call(this.selfLabels, key)) {
            const element = this.selfLabels[key];

            if (node.labels[key] === element) {
              deleteKey.push(key);
            }
          }
        }
      }

      deleteKey.forEach((item) => {
        delete node.labels[item];
      });

      const opt = {
        method: 'put', data: node, force: true
      };

      await this.$ctx.dispatch('rancher/find', {
        type: NORMAN.NODE, id: node.id, opt
      }, { root: true });

      const updateStatus = async() => {
        let canUpdate = true;
        const modifiedNode = (await this.getV3Node())[0];

        for (const key in this.selfLabels) {
          if (Object.hasOwnProperty.call(this.selfLabels, key)) {
            const element = this.selfLabels[key];

            if (enabled) {
              if (modifiedNode.labels[key] !== element) {
                canUpdate = false;
              }
            } else if (modifiedNode.labels[key] === element) {
              canUpdate = false;
            }
          }
        }

        if (canUpdate) {
          const opt = {
            url:    `/api/v1/label_server/${ this.id }/update_status`,
            method: 'post',
            data:   { is_labels_enabled: enabled }
          };

          const res = await this.$ctx.dispatch('request', opt);

          if ( res?._status === 200 && res.msg === 'successful') {
            this.rows = await this.$ctx.dispatch('findAll', { type: this.type, opt: { force: true } });
          }
        } else if (rotationNum > 0 ) {
          rotationNum--;
          await executeUpdate();
        }
      };

      const executeUpdate = async() => {
        outTime = outTime * 2;
        await new Promise((resolve, reject) => {
          setTimeout(async() => {
            try {
              await updateStatus();
              resolve();
            } catch (error) {
              reject(error);
            }
          }, outTime);
        });
      };

      await executeUpdate();
    } catch (error) {
      console.log('更新错误', error); // eslint-disable-line no-console
    }
  }

  async activate() {
    await this.setEnabled(true);
  }

  async activateBulk(items) {
    for (const item of items) {
      await item.setEnabled(true);
    }
  }

  async deactivate() {
    await this.setEnabled(false);
  }

  async deactivateBulk(items) {
    for (const item of items) {
      await item.setEnabled(false);
    }
  }
}
