<script>
import LabeledInput from '@/components/form/LabeledInput';
import LabeledSelect from '@/components/form/LabeledSelect';
import CruResource from '@/components/CruResource';
import RadioGroup from '@/components/form/RadioGroup';
import KeyValue from '@/components/form/KeyValue';
import createEditView from '@/mixins/create-edit-view';
import { CMDBMGMT } from '@/config/types';

export default {
  components: {
    CruResource,
    LabeledInput,
    LabeledSelect,
    RadioGroup,
    KeyValue
  },

  mixins: [createEditView],

  async fetch() {
    const project = (await this.$store.dispatch('kunYao/findAll', { type: CMDBMGMT.PROJECTLIST })).filter(item => !item.is_deleted);

    this.projectOption = project.map(item => ({ label: item.name, value: +item.id }));
  },

  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'create'
    },
  },

  data() {
    return {
      labelOption:    [],
      projectOption:  [],
      categoryOption: [
        { label: 'ConfigTag', value: 1 },
        { label: 'ProjectLabel', value: 2 },
      ]
    };
  },

  beforeMount() {
    !this.value.label && this.$set(this.value, 'label', { '': '' });
    !this.value.project && this.$set(this.value, 'project', { id: '' });
  },

  methods: {
    onSave(btnCb) {
      if (this.isCreate) {
        this.value.is_deleted = false;
        this.value.project = this.value.project.id ?? this.value.project;
      }
      this.save(btnCb);
    },
    error() {
      this.errors = [];
    }
  }
};

</script>

<template>
  <div class="filled-height">
    <CruResource
      :resource="{}"
      :can-yaml="false"
      :mode="mode"
      :errors="errors"
      :done-route="doneRoute"
      @error="error"
      @finish="onSave"
    >
      <div class="row mb-20">
        <div class="col span-6">
          <KeyValue
            key="labels"
            v-model="value.label"
            :add-label="t('labels.addLabel')"
            :mode="mode"
            :title="t('tableHeaders.labels')"
            :read-allowed="false"
            :add-allowed="false"
            :remove-allowed="false"
          />
        </div>
      </div>
      <div class="row mb-20">
        <div class="col span-4">
          <LabeledSelect
            v-model="value.category"
            :mode="mode"
            :label="t('tableHeaders.category')"
            :options="categoryOption"
            required
          />
        </div>
        <div class="col span-4">
          <LabeledSelect
            v-model="value.project.id"
            :mode="mode"
            :label="t('tableHeaders.project')"
            :options="projectOption"
            required
          />
        </div>
        <div class="col span-4">
          <LabeledInput
            v-model="value.comment"
            :mode="mode"
            :label="t('tableHeaders.comment')"
          />
        </div>
      </div>
      <div class="row mb-20">
        <div class="col span-6">
          <KeyValue
            key="annotations"
            v-model="value.chart_vars"
            :add-label="t('generic.add')"
            :mode="mode"
            :title="t('tableHeaders.chartVars')"
            :read-allowed="false"
            :value-can-be-empty="true"
          />
        </div>
      </div>
      <div v-if="!isCreate">
        <div class="row">
          <div class="col span-6">
            <RadioGroup
              v-model="value.is_deleted"
              name="is_deleted"
              :label="t('tableHeaders.isDeleted')"
              :labels="['false', 'true']"
              :options="[false, true]"
              :mode="mode"
            />
          </div>
        </div>
      </div>
    </CruResource>
  </div>
</template>

<style scoped>

</style>>
