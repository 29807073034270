import { DSL } from '@/store/type-map';
import { CMDBMGMT } from '@/config/types';
import { NAME_KY, COMMENT, UPDATED, AGE_KY } from '@/config/table-headers';

export const NAME = 'cmdb';

export function init(store) {
  const {
    product,
    basicType,
    headers,
    virtualType,
    configureType,
  } = DSL(store, NAME);

  product({
    inStore:             'kunYao',
    icon:                'edit',
    removable:           false,
    showClusterSwitcher: false,
    weight:              3,
    category:            'business',
  });

  basicType([
    CMDBMGMT.CLUSTERLIST,
    CMDBMGMT.NETWORKLIST,
    CMDBMGMT.SERVERLIST,
    CMDBMGMT.JUMPSERVERLIST,
    CMDBMGMT.LABELLIST,
    CMDBMGMT.KYNAMESPACELIST,
    CMDBMGMT.PROJECTLIST,
    CMDBMGMT.RELEASELIST,
  ]);

  virtualType({
    labelKey: 'kunYao.cluster.label',
    name:     CMDBMGMT.CLUSTERLIST,
    icon:     'globe',
    route:    {
      name:   `c-cluster-${ NAME }-resource`,
      params: { resource: CMDBMGMT.CLUSTERLIST }
    }
  });

  virtualType({
    labelKey: 'kunYao.network.label',
    name:     CMDBMGMT.NETWORKLIST,
    icon:     'globe',
    route:    {
      name:   `c-cluster-${ NAME }-resource`,
      params: { resource: CMDBMGMT.NETWORKLIST }
    }
  });

  virtualType({
    labelKey: 'kunYao.servers.label',
    name:     CMDBMGMT.SERVERLIST,
    icon:     'globe',
    route:    {
      name:   `c-cluster-${ NAME }-resource`,
      params: { resource: CMDBMGMT.SERVERLIST }
    }
  });

  virtualType({
    labelKey: 'kunYao.jumpserver.label',
    name:     CMDBMGMT.JUMPSERVERLIST,
    icon:     'globe',
    route:    {
      name:   `c-cluster-${ NAME }-resource`,
      params: { resource: CMDBMGMT.JUMPSERVERLIST }
    }
  });

  virtualType({
    labelKey: 'kunYao.label.label',
    name:     CMDBMGMT.LABELLIST,
    icon:     'globe',
    route:    {
      name:   `c-cluster-${ NAME }-resource`,
      params: { resource: CMDBMGMT.LABELLIST }
    }
  });

  virtualType({
    labelKey: 'kunYao.kynamespace.label',
    name:     CMDBMGMT.KYNAMESPACELIST,
    icon:     'globe',
    route:    {
      name:   `c-cluster-${ NAME }-resource`,
      params: { resource: CMDBMGMT.KYNAMESPACELIST }
    }
  });

  virtualType({
    labelKey: 'kunYao.project.label',
    name:     CMDBMGMT.PROJECTLIST,
    icon:     'globe',
    route:    {
      name:   `c-cluster-${ NAME }-resource`,
      params: { resource: CMDBMGMT.PROJECTLIST }
    }
  });

  virtualType({
    labelKey: 'kunYao.release.label',
    name:     CMDBMGMT.RELEASELIST,
    icon:     'globe',
    route:    {
      name:   `c-cluster-${ NAME }-resource`,
      params: { resource: CMDBMGMT.RELEASELIST }
    }
  });

  configureType(CMDBMGMT.SERVERLIST, { showListMasthead: false });

  headers(CMDBMGMT.SERVERLIST, [
    {
      labelKey: 'tableHeaders.privateIp',
      value:    'private_ip',
      name:     'private_ip',
    },
    {
      labelKey: 'tableHeaders.wireGuardIp',
      value:    'wireguard_ip',
      name:     'wireguard_ip',
    },
    {
      labelKey: 'tableHeaders.cluster',
      value:    'cluster.name',
      name:     'globe',
    },
    {
      labelKey: 'tableHeaders.network',
      value:    'network.name',
      name:     'network',
    },
    COMMENT,
    AGE_KY,
    UPDATED,
    {
      labelKey:      'tableHeaders.wireGuardHealth',
      value:         'wireguard_status',
      name:          'wireGuardHealth',
      formatter:     'BadgeStateFormatter',
      formatterOpts: { arbitrary: true },
    },
  ]);

  headers(CMDBMGMT.CLUSTERLIST, [
    NAME_KY,
    COMMENT,
    AGE_KY,
    UPDATED,
  ]);

  headers(CMDBMGMT.NETWORKLIST, [
    NAME_KY,
    {
      labelKey: 'tableHeaders.IDC',
      value:    'idc_name',
      name:     'idc_name',
    },
    {
      labelKey: 'tableHeaders.idcHarbor',
      value:    'idc_harbor',
      name:     'idc_harbor',
    },
    {
      labelKey: 'tableHeaders.HarborUsername',
      value:    'idc_harbor_username',
      name:     'harbor_username',
    },
    {
      labelKey: 'tableHeaders.HarborPassword',
      value:    'idc_harbor_password',
      name:     'harbor_password',
    },
    COMMENT,
    AGE_KY,
    UPDATED,
  ]);

  headers(CMDBMGMT.JUMPSERVERLIST, [
    {
      labelKey:      'tableHeaders.publicKey',
      value:         'public_key',
      name:          'public_key',
      formatter:     'CopyToClipboard',
      formatterOpts: { ellipsis: true },
    },
    {
      labelKey:  'tableHeaders.network',
      value:     'network.name',
      name:      'network',
    },
    COMMENT,
    AGE_KY,
    UPDATED,
  ]);

  headers(CMDBMGMT.LABELLIST, [
    {
      labelKey:  'tableHeaders.labels',
      value:     'label',
      name:      'label',
      sort:      'label',
      formatter: 'KeyValue',
    },
    {
      labelKey: 'tableHeaders.project',
      value:    'project.name',
      name:     'project',
    },
    {
      labelKey:  'tableHeaders.chartVars',
      value:     'chart_vars',
      name:      'chart_vars',
      sort:      'chart_vars',
      formatter: 'KeyValue',
    },
    {
      labelKey: 'tableHeaders.category',
      value:    (row) => {
        if (row.category === 1) {
          return 'Config';
        } else if (row.category === 2) {
          return 'Project';
        }
      },
      name: 'category',
    },
    COMMENT,
    AGE_KY,
    UPDATED,
  ]);

  headers(CMDBMGMT.KYNAMESPACELIST, [
    NAME_KY,
    AGE_KY,
    UPDATED,
  ]);

  headers(CMDBMGMT.PROJECTLIST, [
    NAME_KY,
    AGE_KY,
    UPDATED,
  ]);

  headers(CMDBMGMT.RELEASELIST, [
    {
      labelKey: 'tableHeaders.release',
      value:    'release',
      name:     'release',
    },
    {
      labelKey: 'tableHeaders.namespace',
      value:    'namespace.name',
      name:     'namespace',
    },
    AGE_KY,
    UPDATED,
  ]);
}
