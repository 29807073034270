import { DSL } from '@/store/type-map';
import { LABELMGMT } from '@/config/types';
import { STATE, NAMESPACE } from '@/config/table-headers';

export const NAME = 'labelManager';

export function init(store) {
  const {
    product,
    basicType,
    headers,
    virtualType,
    configureType,
  } = DSL(store, NAME);

  product({
    // ifHaveType:          new RegExp(`${ MANAGEMENT.SETTING }|${ MANAGEMENT.FEATURE }`, 'i'),
    // ifHaveVerb:          'PUT',
    inStore:             'kunYao',
    icon:                'repository',
    removable:           false,
    showClusterSwitcher: false,
    category:            'business',
  });

  basicType([
    LABELMGMT.SERVELABEL,
    LABELMGMT.CONFIGTAG,
    LABELMGMT.PROJECTLABEL,
    LABELMGMT.RELEASEMANAGEMENT,
  ]);

  virtualType({
    labelKey:       'kunYao.label.serveLabel.title',
    name:           LABELMGMT.SERVELABEL,
    weight:         0,
    icon:           'globe',
    route:          {
      name:   'c-cluster-labelManager-resource',
      params: {
        product:  NAME,
        resource: LABELMGMT.SERVELABEL
      }
    }
  });

  virtualType({
    labelKey:       'kunYao.label.configTag.title',
    name:           LABELMGMT.CONFIGTAG,
    // namespaced:     false,
    weight:         3,
    icon:           'globe',
    route:          {
      name:   'c-cluster-labelManager-resource',
      params: {
        product:  NAME,
        resource: LABELMGMT.CONFIGTAG
      }
    }
  });

  virtualType({
    labelKey:       'kunYao.label.projectLabel.title',
    name:           LABELMGMT.PROJECTLABEL,
    // namespaced:     false,
    weight:         2,
    icon:           'globe',
    route:          {
      name:   'c-cluster-labelManager-resource',
      params: {
        product:  NAME,
        resource: LABELMGMT.PROJECTLABEL
      }
    }
  });

  virtualType({
    labelKey:       'kunYao.label.releaseManagement.title',
    name:           LABELMGMT.RELEASEMANAGEMENT,
    // namespaced:     false,
    weight:         1,
    icon:           'globe',
    route:          {
      name:   'c-cluster-labelManager-resource',
      params: {
        product:  NAME,
        resource: LABELMGMT.RELEASEMANAGEMENT
      }
    }
  });

  configureType(LABELMGMT.SERVELABEL, {
    isCreatable:      false,
    isEditable:       true,
    isRemovable:        false,
    canClone:         false,
    canCustomEdit:    true,
  });

  headers(LABELMGMT.SERVELABEL,
    [
      {
        ...STATE,
        value:         row => row.is_labels_enabled ? 'active' : 'off',
        formatterOpts: { arbitrary: true },
      },
      {
        name:      'IDC',
        labelKey:  'tableHeaders.IDC',
        value:     'network.idc_name',
        sort:     'network.idc_name',
      },
      {
        name:      'IP',
        labelKey:  'tableHeaders.serverIp',
        value:     'wireguard_ip',
        sort:      'wireguard_ip',
      },
      {
        name:      'ConfigLabel',
        labelKey:  'tableHeaders.configLabel',
        value:     (row) => {
          let labels = {};

          row.labels.forEach((item) => {
            if (item.category === 1) {
              labels = { ...labels, ...item.label };
            }
          });

          return labels;
        },
        formatter: 'keyValue',
      },
      {
        name:      'ProjectLabel',
        labelKey:  'tableHeaders.projectLabel',
        value:     (row) => {
          let labels = {};

          row.labels.forEach((item) => {
            if (item.category === 2) {
              labels = { ...labels, ...item.label };
            }
          });

          return labels;
        },
        formatter: 'keyValue',
      },
      {
        name:      'UpdateTime',
        labelKey:  'tableHeaders.updated',
        value:     'updated_at',
        sort:      'updated_at:desc',
        formatter: 'Date',
      },
    ]
  );

  configureType(LABELMGMT.CONFIGTAG, {
    isCreatable:   true,
    isEditable:    true,
    canDelete:     true,
    canClone:      true,
    canCustomEdit:   true,
    showState:      false,
    showAge:       false,
  });

  headers(LABELMGMT.CONFIGTAG,
    [
      {
        name:      'ConfigLabel',
        labelKey:  'tableHeaders.configLabel',
        value:     'label',
        formatter: 'KeyValue',
      },
      {
        name:     'Project',
        labelKey: 'tableHeaders.project',
        value:    'project.name',
        sort:     'project.name',
      },
      {
        name:      'envList',
        labelKey:  'tableHeaders.envList',
        value:     'chart_vars',
        formatter: 'KeyValue',
      },
      {
        name:      'comment',
        labelKey:  'tableHeaders.comment',
        value:     'comment',
        sort:      'comment',
      },
    ]
  );

  configureType(LABELMGMT.PROJECTLABEL, {
    isCreatable:   true,
    isEditable:    true,
    canDelete:     true,
    canClone:      true,
    canCustomEdit:   true,
    showState:      false,
    showAge:       false,
  });

  headers(LABELMGMT.PROJECTLABEL,
    [
      {
        name:      'ProjectLabel',
        labelKey:  'tableHeaders.projectLabel',
        value:     'label',
        formatter: 'KeyValue',
      },
      {
        name:     'Project',
        labelKey: 'tableHeaders.project',
        value:    'project.name',
        sort:     'project.name',
      },
      {
        name:      'minerinfolist',
        labelKey:  'tableHeaders.minerInfoList',
        value:     'chart_vars',
        formatter: 'KeyValue',
      },
      {
        name:      'comment',
        labelKey:  'tableHeaders.comment',
        value:     'comment',
        sort:     'comment',
      },
    ]
  );

  configureType(LABELMGMT.RELEASEMANAGEMENT, {
    isCreatable:   true,
    isEditable:    true,
    canDelete:     true,
    canClone:      true,
    canCustomEdit:   true,
    showState:      false,
    showAge:       false,
  });

  headers(LABELMGMT.RELEASEMANAGEMENT,
    [
      {
        name:      'Release',
        labelKey:  'tableHeaders.release',
        value:     'release',
        sort:     'release',
      },
      {
        name:      'Chart',
        labelKey:  'tableHeaders.chart',
        value:     'chart',
        sort:     'chart',
      },
      {
        name:      'Image',
        labelKey:  'tableHeaders.image',
        value:     'image',
        sort:     'image',
      },
      {
        name:      'Tag',
        labelKey:  'tableHeaders.tag',
        value:     'tag',
        sort:     'tag',
      },
      {
        name:      'replica',
        labelKey:  'tableHeaders.replicas',
        value:     'replica',
        sort:     'replica',
      },
      {
        ...NAMESPACE,
        value: 'namespace.name',
        sort:  'namespace.name',
      },
      {
        name:      'ConfigLabel',
        labelKey:  'tableHeaders.configLabel',
        value:     (row) => {
          let labels = {};

          row.labels.forEach((item) => {
            if (item.category === 1) {
              labels = { ...labels, ...item.label };
            }
          });

          return labels;
        },
        sort:      'namespace.name',
        formatter: 'KeyValue',
      },
      {
        name:      'ProjectLabel',
        labelKey:  'tableHeaders.projectLabel',
        value:     (row) => {
          let labels = {};

          row.labels.forEach((item) => {
            if (item.category === 2) {
              labels = { ...labels, ...item.label };
            }
          });

          return labels;
        },
        sort:      'namespace.name',
        formatter: 'KeyValue',
      },
    ]
  );
}
