import { DSL } from '@/store/type-map';
import { SERVERMGMT } from '@/config/types';

export const NAME = 'serverMgmt';

export function init(store) {
  const {
    product,
    basicType,
    virtualType,
  } = DSL(store, NAME);

  product({
    // ifHaveType:          new RegExp(`${ MANAGEMENT.SETTING }|${ MANAGEMENT.FEATURE }`, 'i'),
    // ifHaveVerb:          'PUT',
    inStore:             'kunYao',
    icon:                'cluster',
    removable:           false,
    showClusterSwitcher: false,
    weight:              3,
    category:            'business',
  });

  basicType([
    SERVERMGMT.SERVERLIST,
    'nodes',
    'pods',
  ]);

  virtualType({
    labelKey:       'kunYao.servers.label',
    name:           SERVERMGMT.SERVERLIST,
    // namespaced:     false,
    // weight:         100,
    icon:           'globe',
    route:          {
      name:   'c-cluster-serverMgmt-servers',
      params: {
        cluster:  'local',
        product:  NAME,
        resource: SERVERMGMT.SERVERLIST
      }
    }
  });

  virtualType({
    labelKey:       'kunYao.nodes.label',
    name:           'nodes',
    // namespaced:     false,
    // weight:         98,
    icon:           'globe',
    route:          {
      name:   'c-cluster-serverMgmt-nodes',
      params: {
        cluster:  'local',
        product:  NAME,
        resource: 'node'
      }
    }
  });

  virtualType({
    labelKey:       'kunYao.pods.label',
    name:           'pods',
    // namespaced:     false,
    // weight:         98,
    icon:           'globe',
    route:          {
      name:   'c-cluster-serverMgmt-pods',
      params: {
        cluster:  'local',
        product:  NAME,
        resource: 'pod'
      }
    }
  });

  // configureType(MANAGEMENT.SETTING, {
  //   isCreatable: false,
  //   isRemovable: false,
  //   showAge:     false,
  //   showState:   false,
  //   canYaml:     false,
  // });
  //
  // configureType(MANAGEMENT.FEATURE, {
  //   isCreatable: false,
  //   isRemovable: false,
  //   showAge:     false,
  //   showState:   true,
  //   canYaml:     false,
  // });
  //
  // headers(MANAGEMENT.FEATURE, [
  //   STATE,
  //   NAME_UNLINKED,
  //   FEATURE_DESCRIPTION,
  //   RESTART,
  // ]);
  //
  // hideBulkActions(MANAGEMENT.FEATURE, true);
}
