import NormanModel from '@/plugins/steve/norman-class';
import isEmpty from 'lodash/isEmpty';
import schemas from '@/config/schemas/kunYao';
import { isArray } from '@/utils/array';
import { LABELMGMT, CMDBMGMT } from '@/config/types';
import { clone } from '@/utils/object';

export default class Label extends NormanModel {
  get doneRoute() {
    return 'c-cluster-labelManager-resource';
  }

  get listLocation() {
    return { name: this.doneRoute, params: { resource: this.type } };
  }

  get doneOverride() {
    return this.listLocation;
  }

  get parentLocationOverride() {
    return this.listLocation;
  }

  // 自定义
  removeMulti(params) {
  }

  save() {
    if (this.id) {
      const opt = { url: `${ this.linkFor('update') }/${ this.id }` };

      return this._save(opt);
    } else {
      return this._save();
    }
  }

  async _save(opt = {}) {
    delete this.__rehydrate;
    delete this.__clone;
    const forNew = !this.id;

    const errors = await this.validationErrors(this, opt.ignoreFields);

    if (!isEmpty(errors)) {
      return Promise.reject(errors);
    }

    if ( this.metadata?.resourceVersion ) {
      this.metadata.resourceVersion = `${ this.metadata.resourceVersion }`;
    }

    if ( !opt.url ) {
      if ( forNew ) {
        const schema = this.$getters['schemaFor'](this.type);
        let url = schema.linkFor('update');

        if ( schema.attributes && schema.attributes.namespaced && this.metadata && this.metadata.namespace ) {
          url += `/${ this.metadata.namespace }`;
        }

        opt.url = url;
      } else {
        opt.url = this.linkFor('update') || this.linkFor('self');
      }
    }

    if ( !opt.method ) {
      opt.method = ( forNew ? 'post' : 'put' );
    }

    if ( !opt.headers ) {
      opt.headers = {};
    }

    if ( !opt.headers['content-type'] ) {
      opt.headers['content-type'] = 'application/json';
    }

    if ( !opt.headers['accept'] ) {
      opt.headers['accept'] = 'application/json';
    }

    // @TODO remove this once the API maps steve _type <-> k8s type in both directions
    opt.data = { ...this };

    if (opt?.data._type) {
      opt.data.type = opt.data._type;
    }

    if (opt?.data._name) {
      opt.data.name = opt.data._name;
    }

    if (opt?.data._labels) {
      opt.data.labels = opt.data._labels;
    }

    if (opt?.data._annotations) {
      opt.data.annotations = opt.data._annotations;
    }

    try {
      const res = await this.$dispatch('request', opt);
      const schema = schemas.find(item => item.id === this.type);

      if (isArray(res.data)) {
        res.data = res.data[0];
      }

      res.data.id = `${ res?.data?.id || this.id }`;

      res.data.type = schema.id;
      res.data.links = schema.links;
      // console.log('### Resource Save', this.type, this.id);

      const dictionaries = {
        [LABELMGMT.SERVELABEL]:        CMDBMGMT.SERVERLIST,
        [LABELMGMT.CONFIGTAG]:         CMDBMGMT.LABELLIST,
        [LABELMGMT.PROJECTLABEL]:      CMDBMGMT.LABELLIST,
        [LABELMGMT.RELEASEMANAGEMENT]: CMDBMGMT.RELEASELIST,
      };

      // Steve sometimes returns Table responses instead of the resource you just saved.. ignore
      if ( res && res.kind !== 'Table') {
        await this.$dispatch('load', { data: res.data, existing: (forNew ? this : undefined ) });
        const repeatModule = dictionaries[res.data.type];

        if (repeatModule) {
          const data = clone(res.data);

          data.type = repeatModule;
          await this.$dispatch('load', { data, existing: (forNew ? this : undefined ) });
        }
      }
    } catch (e) {
      if ( this.type && this.id && e?._status === 409) {
        // If there's a conflict, try to load the new version
        await this.$dispatch('find', {
          type: this.type,
          id:   this.id,
          opt:  { force: true }
        });
      }

      return Promise.reject(e);
    }

    return this;
  }

  remove() {
    this.setIsDeleted(true);
  }

  async setIsDeleted(enabled) {
    try {
      const opt = {
        url:    `${ this.linkFor('remove') }/${ this.id }`,
        method: 'patch',
        data:   { is_deleted: enabled },
      };

      const res = await this.$dispatch('request', opt);

      if ( res?._status === 200) {
        const schema = schemas.find(item => item.id === this.type);

        res.id = `${ res?.id || this.id }`;
        res.type = schema.id;
        res.links = schema.links;
        await this.$dispatch('load', { data: res });
      }
    } catch (e) {
      if ( this.type && this.id && e?._status === 409) {
        // If there's a conflict, try to load the new version
        await this.$dispatch('find', {
          type: this.type,
          id:   this.id,
          opt:  { force: true }
        });
      }

      return Promise.reject(e);
    }
  }
}
