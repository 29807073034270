<script>
import LabeledInput from '@/components/form/LabeledInput';
import CruResource from '@/components/CruResource';
import LabeledSelect from '@/components/form/LabeledSelect';
import KeyValue from '@/components/form/KeyValue';
import createEditView from '@/mixins/create-edit-view';
import { _EDIT, _VIEW } from '@/config/query-params';
import { CMDBMGMT } from '@/config/types';

export default {
  components: {
    CruResource,
    LabeledInput,
    LabeledSelect,
    KeyValue,
  },

  mixins: [createEditView],

  async fetch() {
    const project = (await this.$store.dispatch('kunYao/findAll', { type: CMDBMGMT.PROJECTLIST })).filter(item => !item.is_deleted);

    this.projectOption = project.map(item => ({
      label: item.name, value: +item.id, id: +item.id
    }));
  },

  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'create'
    },
  },

  data() {
    return {
      projectOption: [],
      projectId:     this.value?.project?.id || '',
    };
  },

  beforeMount() {
    this.value.category = 1;
    !this.value.label && this.$set(this.value, 'label', { '': '' });
  },

  computed: {
    doneLocationOverride() {
      return this.value.doneOverride;
    },
    isEdit_label() {
      return this.mode === _EDIT ? _VIEW : this.mode;
    }
  },

  methods: {
    onSave(btnCb) {
      this.value.project = this.projectId;
      this.save(btnCb);
    },
    error() {
      this.errors = [];
    }
  }
};
</script>

<template>
  <div class="filled-height">
    <CruResource
      :resource="{}"
      :can-yaml="false"
      :mode="mode"
      :errors="errors"
      :done-route="doneRoute"
      @error="error"
      @finish="onSave"
    >
      <div class="row mb-20">
        <div class="col span-6">
          <KeyValue
            key="labels"
            v-model="value.label"
            :add-label="t('labels.addLabel')"
            :mode="isEdit_label"
            :title="t('tableHeaders.configLabel')"
            :read-allowed="false"
            :add-allowed="false"
            :remove-allowed="false"
          />
        </div>
      </div>
      <div class="row mb-20">
        <div class="col span-6">
          <LabeledSelect
            v-model="projectId"
            :label="t('tableHeaders.project')"
            :options="projectOption"
            required
          />
        </div>
      </div>
      <div class="row mb-20">
        <div class="col span-6">
          <KeyValue
            key="annotations"
            v-model="value.chart_vars"
            :add-label="t('generic.add')"
            :mode="mode"
            :title="t('tableHeaders.envList')"
            :read-allowed="false"
            :value-can-be-empty="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col span-6">
          <LabeledInput
            v-model="value.comment"
            :mode="mode"
            :label="t('tableHeaders.comment')"
          />
        </div>
      </div>
    </CruResource>
  </div>
</template>

<style scoped>

</style>>
