<script>
import LabeledInput from '@/components/form/LabeledInput';
import CruResource from '@/components/CruResource';
import createEditView from '@/mixins/create-edit-view';
import ArrayListSelect from '@/components/form/ArrayListSelect';
import { LABELMGMT } from '@/config/types';
import { allHash } from '@/utils/promise';
import toPairs from 'lodash/toPairs';

export default {
  components: {
    CruResource,
    LabeledInput,
    ArrayListSelect,
  },

  mixins: [createEditView],

  async fetch() {
    const store = this.$store;
    const resource = this.resource;

    const inStore = store.getters['currentStore'](resource);

    const hash = await allHash({
      configLabels:  store.dispatch(`${ inStore }/findAll`, { type: LABELMGMT.CONFIGTAG }),
      projectLabels: store.dispatch(`${ inStore }/findAll`, { type: LABELMGMT.PROJECTLABEL }),
    });

    this.configLabelOption = hash.configLabels.filter(item => !item.is_deleted).map(item => ({ label: toPairs(item.label)[0].join('='), value: item.id }));
    this.projectLabelOption = hash.projectLabels.filter(item => !item.is_deleted).map(item => ({ label: toPairs(item.label)[0].join('='), value: item.id }));
  },

  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'create'
    }
  },

  data() {
    return {
      configLabelOption:  [],
      projectLabelOption: [],
      configLabels:       [],
      projectLabels:      [],
    };
  },

  mounted() {
    const configLabels = [];
    const projectLabels = [];

    this.value.labels?.forEach((item) => {
      if (item.category === 1) {
        !item.is_deleted && configLabels.push(`${ item.id }`);
      } else {
        !item.is_deleted && projectLabels.push(`${ item.id }`);
      }
    });

    this.configLabels = configLabels;
    this.projectLabels = projectLabels;
  },

  computed: {
    labels() {
      return this.configLabels.concat(this.projectLabels);
    }
  },

  methods: {
    onSave(btnCb) {
      this.value.labels = this.labels.filter(item => item).join(',');
      this.save(btnCb);
    },
    error() {
      this.errors = [];
    }
  }
};

</script>

<template>
  <div class="filled-height">
    <CruResource
      :resource="{}"
      :can-yaml="false"
      :mode="mode"
      :errors="errors"
      :done-route="doneRoute"
      @error="error"
      @finish="onSave"
    >
      <div class="row mb-20">
        <div class="col span-6">
          <LabeledInput
            v-model="value.network.idc_name"
            :disabled="true"
            :label="t('tableHeaders.serverIp')"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model="value.wireguard_ip"
            :disabled="true"
            :label="t('tableHeaders.serverIp')"
          />
        </div>
      </div>
      <div class="row mb-20">
        <div class="col span-6">
          <ArrayListSelect
            v-model="configLabels"
            :options="configLabelOption"
            :array-list-props="{
              mode: mode,
              addLabel: t('generic.add'),
              title: t('tableHeaders.configLabel'),
            }"
            :loading="$fetchState.pending"
          />
        </div>
      </div>
      <div class="row mb-20">
        <div class="col span-6">
          <ArrayListSelect
            v-model="projectLabels"
            :options="projectLabelOption"
            :array-list-props="{
              mode: mode,
              addLabel: t('generic.add') ,
              title: t('tableHeaders.projectLabel'),
            }"
            :loading="$fetchState.pending"
          />
        </div>
      </div>
    </CruResource>
  </div>
</template>

<style scoped>

</style>>
