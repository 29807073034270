import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23964c97 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _ee4d600c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _55e4cfa0 = () => interopDefault(import('../pages/c/index.vue' /* webpackChunkName: "pages/c/index" */))
const _251d263c = () => interopDefault(import('../pages/clusters/index.vue' /* webpackChunkName: "pages/clusters/index" */))
const _afbb4faa = () => interopDefault(import('../pages/design-system/index.vue' /* webpackChunkName: "pages/design-system/index" */))
const _3532d1a0 = () => interopDefault(import('../pages/fail-whale.vue' /* webpackChunkName: "pages/fail-whale" */))
const _74b49676 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _2706823a = () => interopDefault(import('../pages/prefs.vue' /* webpackChunkName: "pages/prefs" */))
const _0ac3231c = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _134d9e16 = () => interopDefault(import('../pages/account/create-key.vue' /* webpackChunkName: "pages/account/create-key" */))
const _08b9b828 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _5b8e555b = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _67d74d88 = () => interopDefault(import('../pages/auth/setup.vue' /* webpackChunkName: "pages/auth/setup" */))
const _3bb5b8ca = () => interopDefault(import('../pages/auth/verify.vue' /* webpackChunkName: "pages/auth/verify" */))
const _543f8978 = () => interopDefault(import('../pages/design-system/form-controls.vue' /* webpackChunkName: "pages/design-system/form-controls" */))
const _94f83226 = () => interopDefault(import('../pages/design-system/provider-images.vue' /* webpackChunkName: "pages/design-system/provider-images" */))
const _2fa07a75 = () => interopDefault(import('../pages/docs/toc.js' /* webpackChunkName: "pages/docs/toc" */))
const _4b9a2a2c = () => interopDefault(import('../pages/rio/mesh.vue' /* webpackChunkName: "pages/rio/mesh" */))
const _14a1120a = () => interopDefault(import('../pages/design-system/page-examples/create.vue' /* webpackChunkName: "pages/design-system/page-examples/create" */))
const _664e3bd0 = () => interopDefault(import('../pages/design-system/page-examples/detail.vue' /* webpackChunkName: "pages/design-system/page-examples/detail" */))
const _3457135d = () => interopDefault(import('../pages/design-system/page-examples/list.vue' /* webpackChunkName: "pages/design-system/page-examples/list" */))
const _4d1cdb10 = () => interopDefault(import('../pages/c/_cluster/index.vue' /* webpackChunkName: "pages/c/_cluster/index" */))
const _15584fd7 = () => interopDefault(import('../pages/docs/_doc.vue' /* webpackChunkName: "pages/docs/_doc" */))
const _6b218a27 = () => interopDefault(import('../pages/c/_cluster/apps/index.vue' /* webpackChunkName: "pages/c/_cluster/apps/index" */))
const _66b8dc46 = () => interopDefault(import('../pages/c/_cluster/auth/index.vue' /* webpackChunkName: "pages/c/_cluster/auth/index" */))
const _9dc21a12 = () => interopDefault(import('../pages/c/_cluster/backup/index.vue' /* webpackChunkName: "pages/c/_cluster/backup/index" */))
const _5864814e = () => interopDefault(import('../pages/c/_cluster/cis/index.vue' /* webpackChunkName: "pages/c/_cluster/cis/index" */))
const _23d06a9d = () => interopDefault(import('../pages/c/_cluster/cmdb/index.vue' /* webpackChunkName: "pages/c/_cluster/cmdb/index" */))
const _ec3704e0 = () => interopDefault(import('../pages/c/_cluster/ecm/index.vue' /* webpackChunkName: "pages/c/_cluster/ecm/index" */))
const _57c8deb4 = () => interopDefault(import('../pages/c/_cluster/explorer/index.vue' /* webpackChunkName: "pages/c/_cluster/explorer/index" */))
const _289f3f0f = () => interopDefault(import('../pages/c/_cluster/fleet/index.vue' /* webpackChunkName: "pages/c/_cluster/fleet/index" */))
const _4dbca5f2 = () => interopDefault(import('../pages/c/_cluster/gatekeeper/index.vue' /* webpackChunkName: "pages/c/_cluster/gatekeeper/index" */))
const _3e7d5697 = () => interopDefault(import('../pages/c/_cluster/harvester/index.vue' /* webpackChunkName: "pages/c/_cluster/harvester/index" */))
const _8122a128 = () => interopDefault(import('../pages/c/_cluster/harvesterManager/index.vue' /* webpackChunkName: "pages/c/_cluster/harvesterManager/index" */))
const _a1ebf2e0 = () => interopDefault(import('../pages/c/_cluster/helmManager/index.vue' /* webpackChunkName: "pages/c/_cluster/helmManager/index" */))
const _d9830b1e = () => interopDefault(import('../pages/c/_cluster/istio/index.vue' /* webpackChunkName: "pages/c/_cluster/istio/index" */))
const _3cffe1e4 = () => interopDefault(import('../pages/c/_cluster/labelManager/index.vue' /* webpackChunkName: "pages/c/_cluster/labelManager/index" */))
const _5fb65784 = () => interopDefault(import('../pages/c/_cluster/legacy/index.vue' /* webpackChunkName: "pages/c/_cluster/legacy/index" */))
const _b9780b40 = () => interopDefault(import('../pages/c/_cluster/logging/index.vue' /* webpackChunkName: "pages/c/_cluster/logging/index" */))
const _75397474 = () => interopDefault(import('../pages/c/_cluster/longhorn/index.vue' /* webpackChunkName: "pages/c/_cluster/longhorn/index" */))
const _0b4fb52e = () => interopDefault(import('../pages/c/_cluster/manager/index.vue' /* webpackChunkName: "pages/c/_cluster/manager/index" */))
const _d9b88a86 = () => interopDefault(import('../pages/c/_cluster/mcapps/index.vue' /* webpackChunkName: "pages/c/_cluster/mcapps/index" */))
const _363d5606 = () => interopDefault(import('../pages/c/_cluster/monitoring/index.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/index" */))
const _43d49f62 = () => interopDefault(import('../pages/c/_cluster/neuvector/index.vue' /* webpackChunkName: "pages/c/_cluster/neuvector/index" */))
const _50c9d459 = () => interopDefault(import('../pages/c/_cluster/serverMgmt/index.vue' /* webpackChunkName: "pages/c/_cluster/serverMgmt/index" */))
const _0f53b598 = () => interopDefault(import('../pages/c/_cluster/settings/index.vue' /* webpackChunkName: "pages/c/_cluster/settings/index" */))
const _ce2b825a = () => interopDefault(import('../pages/c/_cluster/apps/charts/index.vue' /* webpackChunkName: "pages/c/_cluster/apps/charts/index" */))
const _533c990a = () => interopDefault(import('../pages/c/_cluster/auth/config/index.vue' /* webpackChunkName: "pages/c/_cluster/auth/config/index" */))
const _42b5bacb = () => interopDefault(import('../pages/c/_cluster/auth/roles/index.vue' /* webpackChunkName: "pages/c/_cluster/auth/roles/index" */))
const _4fdaddb9 = () => interopDefault(import('../pages/c/_cluster/cmdb/recycleBin.vue' /* webpackChunkName: "pages/c/_cluster/cmdb/recycleBin" */))
const _0f208043 = () => interopDefault(import('../pages/c/_cluster/explorer/ConfigBadge.vue' /* webpackChunkName: "pages/c/_cluster/explorer/ConfigBadge" */))
const _a0ab5212 = () => interopDefault(import('../pages/c/_cluster/explorer/EventsTable.vue' /* webpackChunkName: "pages/c/_cluster/explorer/EventsTable" */))
const _3dadf3be = () => interopDefault(import('../pages/c/_cluster/explorer/explorer-utils.js' /* webpackChunkName: "pages/c/_cluster/explorer/explorer-utils" */))
const _cbad9000 = () => interopDefault(import('../pages/c/_cluster/explorer/tools/index.vue' /* webpackChunkName: "pages/c/_cluster/explorer/tools/index" */))
const _01493959 = () => interopDefault(import('../pages/c/_cluster/gatekeeper/constraints/index.vue' /* webpackChunkName: "pages/c/_cluster/gatekeeper/constraints/index" */))
const _6f2497d8 = () => interopDefault(import('../pages/c/_cluster/harvester/airgapupgrade/index.vue' /* webpackChunkName: "pages/c/_cluster/harvester/airgapupgrade/index" */))
const _2318e757 = () => interopDefault(import('../pages/c/_cluster/harvester/support/index.vue' /* webpackChunkName: "pages/c/_cluster/harvester/support/index" */))
const _7f10835c = () => interopDefault(import('../pages/c/_cluster/helmManager/helmConfig.vue' /* webpackChunkName: "pages/c/_cluster/helmManager/helmConfig" */))
const _187e9fd2 = () => interopDefault(import('../pages/c/_cluster/helmManager/history.vue' /* webpackChunkName: "pages/c/_cluster/helmManager/history" */))
const _f971f54e = () => interopDefault(import('../pages/c/_cluster/helmManager/install.vue' /* webpackChunkName: "pages/c/_cluster/helmManager/install" */))
const _1546e350 = () => interopDefault(import('../pages/c/_cluster/helmManager/list.vue' /* webpackChunkName: "pages/c/_cluster/helmManager/list" */))
const _f518fcd4 = () => interopDefault(import('../pages/c/_cluster/helmManager/rollback.vue' /* webpackChunkName: "pages/c/_cluster/helmManager/rollback" */))
const _402bfba0 = () => interopDefault(import('../pages/c/_cluster/helmManager/uninstall.vue' /* webpackChunkName: "pages/c/_cluster/helmManager/uninstall" */))
const _12aa010a = () => interopDefault(import('../pages/c/_cluster/helmManager/update.vue' /* webpackChunkName: "pages/c/_cluster/helmManager/update" */))
const _404f84c8 = () => interopDefault(import('../pages/c/_cluster/legacy/project/index.vue' /* webpackChunkName: "pages/c/_cluster/legacy/project/index" */))
const _0c2aff4b = () => interopDefault(import('../pages/c/_cluster/manager/cloudCredential/index.vue' /* webpackChunkName: "pages/c/_cluster/manager/cloudCredential/index" */))
const _09662b88 = () => interopDefault(import('../pages/c/_cluster/monitoring/monitor/index.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/monitor/index" */))
const _6279c55b = () => interopDefault(import('../pages/c/_cluster/monitoring/route-receiver/index.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/route-receiver/index" */))
const _c48a9290 = () => interopDefault(import('../pages/c/_cluster/serverMgmt/nodes.vue' /* webpackChunkName: "pages/c/_cluster/serverMgmt/nodes" */))
const _6a250697 = () => interopDefault(import('../pages/c/_cluster/serverMgmt/pods.vue' /* webpackChunkName: "pages/c/_cluster/serverMgmt/pods" */))
const _085b1657 = () => interopDefault(import('../pages/c/_cluster/serverMgmt/servers.vue' /* webpackChunkName: "pages/c/_cluster/serverMgmt/servers" */))
const _0780dd8d = () => interopDefault(import('../pages/c/_cluster/settings/banners.vue' /* webpackChunkName: "pages/c/_cluster/settings/banners" */))
const _108875cd = () => interopDefault(import('../pages/c/_cluster/settings/brand.vue' /* webpackChunkName: "pages/c/_cluster/settings/brand" */))
const _274c87df = () => interopDefault(import('../pages/c/_cluster/apps/charts/chart.vue' /* webpackChunkName: "pages/c/_cluster/apps/charts/chart" */))
const _75fa38dc = () => interopDefault(import('../pages/c/_cluster/apps/charts/install.vue' /* webpackChunkName: "pages/c/_cluster/apps/charts/install" */))
const _363e7763 = () => interopDefault(import('../pages/c/_cluster/auth/group.principal/assign-edit.vue' /* webpackChunkName: "pages/c/_cluster/auth/group.principal/assign-edit" */))
const _65d1ef87 = () => interopDefault(import('../pages/c/_cluster/legacy/project/pipelines.vue' /* webpackChunkName: "pages/c/_cluster/legacy/project/pipelines" */))
const _be2d965a = () => interopDefault(import('../pages/c/_cluster/manager/cloudCredential/create.vue' /* webpackChunkName: "pages/c/_cluster/manager/cloudCredential/create" */))
const _4b139036 = () => interopDefault(import('../pages/c/_cluster/monitoring/monitor/create.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/monitor/create" */))
const _147330c3 = () => interopDefault(import('../pages/c/_cluster/monitoring/route-receiver/create.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/route-receiver/create" */))
const _50bb4391 = () => interopDefault(import('../pages/c/_cluster/explorer/tools/pages/_page.vue' /* webpackChunkName: "pages/c/_cluster/explorer/tools/pages/_page" */))
const _725155f2 = () => interopDefault(import('../pages/c/_cluster/auth/config/_id.vue' /* webpackChunkName: "pages/c/_cluster/auth/config/_id" */))
const _0b578d62 = () => interopDefault(import('../pages/c/_cluster/legacy/pages/_page.vue' /* webpackChunkName: "pages/c/_cluster/legacy/pages/_page" */))
const _adde6d38 = () => interopDefault(import('../pages/c/_cluster/legacy/project/_page.vue' /* webpackChunkName: "pages/c/_cluster/legacy/project/_page" */))
const _20765873 = () => interopDefault(import('../pages/c/_cluster/manager/cloudCredential/_id.vue' /* webpackChunkName: "pages/c/_cluster/manager/cloudCredential/_id" */))
const _8ca2b382 = () => interopDefault(import('../pages/c/_cluster/manager/pages/_page.vue' /* webpackChunkName: "pages/c/_cluster/manager/pages/_page" */))
const _1e46048e = () => interopDefault(import('../pages/c/_cluster/mcapps/pages/_page.vue' /* webpackChunkName: "pages/c/_cluster/mcapps/pages/_page" */))
const _26413afa = () => interopDefault(import('../pages/c/_cluster/monitoring/route-receiver/_id.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/route-receiver/_id" */))
const _46f7ac75 = () => interopDefault(import('../pages/c/_cluster/auth/roles/_resource/create.vue' /* webpackChunkName: "pages/c/_cluster/auth/roles/_resource/create" */))
const _74619702 = () => interopDefault(import('../pages/c/_cluster/harvester/console/_uid/serial.vue' /* webpackChunkName: "pages/c/_cluster/harvester/console/_uid/serial" */))
const _3df68cb0 = () => interopDefault(import('../pages/c/_cluster/harvester/console/_uid/vnc.vue' /* webpackChunkName: "pages/c/_cluster/harvester/console/_uid/vnc" */))
const _708c57de = () => interopDefault(import('../pages/c/_cluster/auth/roles/_resource/_id.vue' /* webpackChunkName: "pages/c/_cluster/auth/roles/_resource/_id" */))
const _7b9d00c1 = () => interopDefault(import('../pages/c/_cluster/monitoring/monitor/_namespace/_id.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/monitor/_namespace/_id" */))
const _00d9bf90 = () => interopDefault(import('../pages/c/_cluster/cmdb/_resource.vue' /* webpackChunkName: "pages/c/_cluster/cmdb/_resource" */))
const _5b092429 = () => interopDefault(import('../pages/c/_cluster/labelManager/_resource.vue' /* webpackChunkName: "pages/c/_cluster/labelManager/_resource" */))
const _384c9477 = () => interopDefault(import('../pages/c/_cluster/navlinks/_group.vue' /* webpackChunkName: "pages/c/_cluster/navlinks/_group" */))
const _392c20e5 = () => interopDefault(import('../pages/c/_cluster/_product/index.vue' /* webpackChunkName: "pages/c/_cluster/_product/index" */))
const _7168386f = () => interopDefault(import('../pages/c/_cluster/_product/members/index.vue' /* webpackChunkName: "pages/c/_cluster/_product/members/index" */))
const _d9dc3916 = () => interopDefault(import('../pages/c/_cluster/_product/namespaces.vue' /* webpackChunkName: "pages/c/_cluster/_product/namespaces" */))
const _75c92d22 = () => interopDefault(import('../pages/c/_cluster/_product/projectsnamespaces.vue' /* webpackChunkName: "pages/c/_cluster/_product/projectsnamespaces" */))
const _09e71903 = () => interopDefault(import('../pages/c/_cluster/_product/_resource/index.vue' /* webpackChunkName: "pages/c/_cluster/_product/_resource/index" */))
const _5ab0521b = () => interopDefault(import('../pages/c/_cluster/_product/_resource/create.vue' /* webpackChunkName: "pages/c/_cluster/_product/_resource/create" */))
const _3b5d802b = () => interopDefault(import('../pages/c/_cluster/_product/_resource/_id.vue' /* webpackChunkName: "pages/c/_cluster/_product/_resource/_id" */))
const _68200da6 = () => interopDefault(import('../pages/c/_cluster/_product/_resource/_namespace/_id.vue' /* webpackChunkName: "pages/c/_cluster/_product/_resource/_namespace/_id" */))
const _434e275c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/dashboard/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _23964c97,
    name: "about"
  }, {
    path: "/account",
    component: _ee4d600c,
    name: "account"
  }, {
    path: "/c",
    component: _55e4cfa0,
    name: "c"
  }, {
    path: "/clusters",
    component: _251d263c,
    name: "clusters"
  }, {
    path: "/design-system",
    component: _afbb4faa,
    name: "design-system"
  }, {
    path: "/fail-whale",
    component: _3532d1a0,
    name: "fail-whale"
  }, {
    path: "/home",
    component: _74b49676,
    name: "home"
  }, {
    path: "/prefs",
    component: _2706823a,
    name: "prefs"
  }, {
    path: "/support",
    component: _0ac3231c,
    name: "support"
  }, {
    path: "/account/create-key",
    component: _134d9e16,
    name: "account-create-key"
  }, {
    path: "/auth/login",
    component: _08b9b828,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _5b8e555b,
    name: "auth-logout"
  }, {
    path: "/auth/setup",
    component: _67d74d88,
    name: "auth-setup"
  }, {
    path: "/auth/verify",
    component: _3bb5b8ca,
    name: "auth-verify"
  }, {
    path: "/design-system/form-controls",
    component: _543f8978,
    name: "design-system-form-controls"
  }, {
    path: "/design-system/provider-images",
    component: _94f83226,
    name: "design-system-provider-images"
  }, {
    path: "/docs/toc",
    component: _2fa07a75,
    name: "docs-toc"
  }, {
    path: "/rio/mesh",
    component: _4b9a2a2c,
    name: "rio-mesh"
  }, {
    path: "/design-system/page-examples/create",
    component: _14a1120a,
    name: "design-system-page-examples-create"
  }, {
    path: "/design-system/page-examples/detail",
    component: _664e3bd0,
    name: "design-system-page-examples-detail"
  }, {
    path: "/design-system/page-examples/list",
    component: _3457135d,
    name: "design-system-page-examples-list"
  }, {
    path: "/c/:cluster",
    component: _4d1cdb10,
    name: "c-cluster"
  }, {
    path: "/docs/:doc?",
    component: _15584fd7,
    name: "docs-doc"
  }, {
    path: "/c/:cluster/apps",
    component: _6b218a27,
    name: "c-cluster-apps"
  }, {
    path: "/c/:cluster/auth",
    component: _66b8dc46,
    name: "c-cluster-auth"
  }, {
    path: "/c/:cluster/backup",
    component: _9dc21a12,
    name: "c-cluster-backup"
  }, {
    path: "/c/:cluster/cis",
    component: _5864814e,
    name: "c-cluster-cis"
  }, {
    path: "/c/:cluster/cmdb",
    component: _23d06a9d,
    name: "c-cluster-cmdb"
  }, {
    path: "/c/:cluster/ecm",
    component: _ec3704e0,
    name: "c-cluster-ecm"
  }, {
    path: "/c/:cluster/explorer",
    component: _57c8deb4,
    name: "c-cluster-explorer"
  }, {
    path: "/c/:cluster/fleet",
    component: _289f3f0f,
    name: "c-cluster-fleet"
  }, {
    path: "/c/:cluster/gatekeeper",
    component: _4dbca5f2,
    name: "c-cluster-gatekeeper"
  }, {
    path: "/c/:cluster/harvester",
    component: _3e7d5697,
    name: "c-cluster-harvester"
  }, {
    path: "/c/:cluster/harvesterManager",
    component: _8122a128,
    name: "c-cluster-harvesterManager"
  }, {
    path: "/c/:cluster/helmManager",
    component: _a1ebf2e0,
    name: "c-cluster-helmManager"
  }, {
    path: "/c/:cluster/istio",
    component: _d9830b1e,
    name: "c-cluster-istio"
  }, {
    path: "/c/:cluster/labelManager",
    component: _3cffe1e4,
    name: "c-cluster-labelManager"
  }, {
    path: "/c/:cluster/legacy",
    component: _5fb65784,
    name: "c-cluster-legacy"
  }, {
    path: "/c/:cluster/logging",
    component: _b9780b40,
    name: "c-cluster-logging"
  }, {
    path: "/c/:cluster/longhorn",
    component: _75397474,
    name: "c-cluster-longhorn"
  }, {
    path: "/c/:cluster/manager",
    component: _0b4fb52e,
    name: "c-cluster-manager"
  }, {
    path: "/c/:cluster/mcapps",
    component: _d9b88a86,
    name: "c-cluster-mcapps"
  }, {
    path: "/c/:cluster/monitoring",
    component: _363d5606,
    name: "c-cluster-monitoring"
  }, {
    path: "/c/:cluster/neuvector",
    component: _43d49f62,
    name: "c-cluster-neuvector"
  }, {
    path: "/c/:cluster/serverMgmt",
    component: _50c9d459,
    name: "c-cluster-serverMgmt"
  }, {
    path: "/c/:cluster/settings",
    component: _0f53b598,
    name: "c-cluster-settings"
  }, {
    path: "/c/:cluster/apps/charts",
    component: _ce2b825a,
    name: "c-cluster-apps-charts"
  }, {
    path: "/c/:cluster/auth/config",
    component: _533c990a,
    name: "c-cluster-auth-config"
  }, {
    path: "/c/:cluster/auth/roles",
    component: _42b5bacb,
    name: "c-cluster-auth-roles"
  }, {
    path: "/c/:cluster/cmdb/recycleBin",
    component: _4fdaddb9,
    name: "c-cluster-cmdb-recycleBin"
  }, {
    path: "/c/:cluster/explorer/ConfigBadge",
    component: _0f208043,
    name: "c-cluster-explorer-ConfigBadge"
  }, {
    path: "/c/:cluster/explorer/EventsTable",
    component: _a0ab5212,
    name: "c-cluster-explorer-EventsTable"
  }, {
    path: "/c/:cluster/explorer/explorer-utils",
    component: _3dadf3be,
    name: "c-cluster-explorer-explorer-utils"
  }, {
    path: "/c/:cluster/explorer/tools",
    component: _cbad9000,
    name: "c-cluster-explorer-tools"
  }, {
    path: "/c/:cluster/gatekeeper/constraints",
    component: _01493959,
    name: "c-cluster-gatekeeper-constraints"
  }, {
    path: "/c/:cluster/harvester/airgapupgrade",
    component: _6f2497d8,
    name: "c-cluster-harvester-airgapupgrade"
  }, {
    path: "/c/:cluster/harvester/support",
    component: _2318e757,
    name: "c-cluster-harvester-support"
  }, {
    path: "/c/:cluster/helmManager/helmConfig",
    component: _7f10835c,
    name: "c-cluster-helmManager-helmConfig"
  }, {
    path: "/c/:cluster/helmManager/history",
    component: _187e9fd2,
    name: "c-cluster-helmManager-history"
  }, {
    path: "/c/:cluster/helmManager/install",
    component: _f971f54e,
    name: "c-cluster-helmManager-install"
  }, {
    path: "/c/:cluster/helmManager/list",
    component: _1546e350,
    name: "c-cluster-helmManager-list"
  }, {
    path: "/c/:cluster/helmManager/rollback",
    component: _f518fcd4,
    name: "c-cluster-helmManager-rollback"
  }, {
    path: "/c/:cluster/helmManager/uninstall",
    component: _402bfba0,
    name: "c-cluster-helmManager-uninstall"
  }, {
    path: "/c/:cluster/helmManager/update",
    component: _12aa010a,
    name: "c-cluster-helmManager-update"
  }, {
    path: "/c/:cluster/legacy/project",
    component: _404f84c8,
    name: "c-cluster-legacy-project"
  }, {
    path: "/c/:cluster/manager/cloudCredential",
    component: _0c2aff4b,
    name: "c-cluster-manager-cloudCredential"
  }, {
    path: "/c/:cluster/monitoring/monitor",
    component: _09662b88,
    name: "c-cluster-monitoring-monitor"
  }, {
    path: "/c/:cluster/monitoring/route-receiver",
    component: _6279c55b,
    name: "c-cluster-monitoring-route-receiver"
  }, {
    path: "/c/:cluster/serverMgmt/nodes",
    component: _c48a9290,
    name: "c-cluster-serverMgmt-nodes"
  }, {
    path: "/c/:cluster/serverMgmt/pods",
    component: _6a250697,
    name: "c-cluster-serverMgmt-pods"
  }, {
    path: "/c/:cluster/serverMgmt/servers",
    component: _085b1657,
    name: "c-cluster-serverMgmt-servers"
  }, {
    path: "/c/:cluster/settings/banners",
    component: _0780dd8d,
    name: "c-cluster-settings-banners"
  }, {
    path: "/c/:cluster/settings/brand",
    component: _108875cd,
    name: "c-cluster-settings-brand"
  }, {
    path: "/c/:cluster/apps/charts/chart",
    component: _274c87df,
    name: "c-cluster-apps-charts-chart"
  }, {
    path: "/c/:cluster/apps/charts/install",
    component: _75fa38dc,
    name: "c-cluster-apps-charts-install"
  }, {
    path: "/c/:cluster/auth/group.principal/assign-edit",
    component: _363e7763,
    name: "c-cluster-auth-group.principal-assign-edit"
  }, {
    path: "/c/:cluster/legacy/project/pipelines",
    component: _65d1ef87,
    name: "c-cluster-legacy-project-pipelines"
  }, {
    path: "/c/:cluster/manager/cloudCredential/create",
    component: _be2d965a,
    name: "c-cluster-manager-cloudCredential-create"
  }, {
    path: "/c/:cluster/monitoring/monitor/create",
    component: _4b139036,
    name: "c-cluster-monitoring-monitor-create"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/create",
    component: _147330c3,
    name: "c-cluster-monitoring-route-receiver-create"
  }, {
    path: "/c/:cluster/explorer/tools/pages/:page?",
    component: _50bb4391,
    name: "c-cluster-explorer-tools-pages-page"
  }, {
    path: "/c/:cluster/auth/config/:id",
    component: _725155f2,
    name: "c-cluster-auth-config-id"
  }, {
    path: "/c/:cluster/legacy/pages/:page?",
    component: _0b578d62,
    name: "c-cluster-legacy-pages-page"
  }, {
    path: "/c/:cluster/legacy/project/:page",
    component: _adde6d38,
    name: "c-cluster-legacy-project-page"
  }, {
    path: "/c/:cluster/manager/cloudCredential/:id",
    component: _20765873,
    name: "c-cluster-manager-cloudCredential-id"
  }, {
    path: "/c/:cluster/manager/pages/:page?",
    component: _8ca2b382,
    name: "c-cluster-manager-pages-page"
  }, {
    path: "/c/:cluster/mcapps/pages/:page?",
    component: _1e46048e,
    name: "c-cluster-mcapps-pages-page"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/:id?",
    component: _26413afa,
    name: "c-cluster-monitoring-route-receiver-id"
  }, {
    path: "/c/:cluster/auth/roles/:resource/create",
    component: _46f7ac75,
    name: "c-cluster-auth-roles-resource-create"
  }, {
    path: "/c/:cluster/harvester/console/:uid?/serial",
    component: _74619702,
    name: "c-cluster-harvester-console-uid-serial"
  }, {
    path: "/c/:cluster/harvester/console/:uid?/vnc",
    component: _3df68cb0,
    name: "c-cluster-harvester-console-uid-vnc"
  }, {
    path: "/c/:cluster/auth/roles/:resource/:id?",
    component: _708c57de,
    name: "c-cluster-auth-roles-resource-id"
  }, {
    path: "/c/:cluster/monitoring/monitor/:namespace/:id?",
    component: _7b9d00c1,
    name: "c-cluster-monitoring-monitor-namespace-id"
  }, {
    path: "/c/:cluster/cmdb/:resource",
    component: _00d9bf90,
    name: "c-cluster-cmdb-resource"
  }, {
    path: "/c/:cluster/labelManager/:resource",
    component: _5b092429,
    name: "c-cluster-labelManager-resource"
  }, {
    path: "/c/:cluster/navlinks/:group?",
    component: _384c9477,
    name: "c-cluster-navlinks-group"
  }, {
    path: "/c/:cluster/:product",
    component: _392c20e5,
    name: "c-cluster-product"
  }, {
    path: "/c/:cluster/:product/members",
    component: _7168386f,
    name: "c-cluster-product-members"
  }, {
    path: "/c/:cluster/:product/namespaces",
    component: _d9dc3916,
    name: "c-cluster-product-namespaces"
  }, {
    path: "/c/:cluster/:product/projectsnamespaces",
    component: _75c92d22,
    name: "c-cluster-product-projectsnamespaces"
  }, {
    path: "/c/:cluster/:product/:resource",
    component: _09e71903,
    name: "c-cluster-product-resource"
  }, {
    path: "/c/:cluster/:product/:resource/create",
    component: _5ab0521b,
    name: "c-cluster-product-resource-create"
  }, {
    path: "/c/:cluster/:product/:resource/:id",
    component: _3b5d802b,
    name: "c-cluster-product-resource-id"
  }, {
    path: "/c/:cluster/:product/:resource/:namespace/:id?",
    component: _68200da6,
    name: "c-cluster-product-resource-namespace-id"
  }, {
    path: "/",
    component: _434e275c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
