var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filled-height"},[_c('CruResource',{attrs:{"resource":{},"can-yaml":false,"mode":_vm.mode,"errors":_vm.errors,"done-route":_vm.doneRoute},on:{"error":_vm.error,"finish":_vm.onSave}},[_c('div',{staticClass:"row mb-20"},[_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"disabled":true,"label":_vm.t('tableHeaders.serverIp')},model:{value:(_vm.value.network.idc_name),callback:function ($$v) {_vm.$set(_vm.value.network, "idc_name", $$v)},expression:"value.network.idc_name"}})],1),_vm._v(" "),_c('div',{staticClass:"col span-6"},[_c('LabeledInput',{attrs:{"disabled":true,"label":_vm.t('tableHeaders.serverIp')},model:{value:(_vm.value.wireguard_ip),callback:function ($$v) {_vm.$set(_vm.value, "wireguard_ip", $$v)},expression:"value.wireguard_ip"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mb-20"},[_c('div',{staticClass:"col span-6"},[_c('ArrayListSelect',{attrs:{"options":_vm.configLabelOption,"array-list-props":{
            mode: _vm.mode,
            addLabel: _vm.t('generic.add'),
            title: _vm.t('tableHeaders.configLabel'),
          },"loading":_vm.$fetchState.pending},model:{value:(_vm.configLabels),callback:function ($$v) {_vm.configLabels=$$v},expression:"configLabels"}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mb-20"},[_c('div',{staticClass:"col span-6"},[_c('ArrayListSelect',{attrs:{"options":_vm.projectLabelOption,"array-list-props":{
            mode: _vm.mode,
            addLabel: _vm.t('generic.add') ,
            title: _vm.t('tableHeaders.projectLabel'),
          },"loading":_vm.$fetchState.pending},model:{value:(_vm.projectLabels),callback:function ($$v) {_vm.projectLabels=$$v},expression:"projectLabels"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }