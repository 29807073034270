import { render, staticRenderFns } from "./label.io.projectlabel.vue?vue&type=template&id=708ecea6&scoped=true&"
import script from "./label.io.projectlabel.vue?vue&type=script&lang=js&"
export * from "./label.io.projectlabel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "708ecea6",
  null
  
)

export default component.exports